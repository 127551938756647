@import "colors";
@import "input";

.SelectInput {
  @include inputContainer;

  .labelText {
    @include labelText;
  }

  .selectBody {
    position: relative;

    .input {
      @include inputBox;
      cursor: pointer;
      background-color: white;

      &:focus {
        @include inputBoxFocused;
      }
    }

    .selectIcon {
      top: 5px;
      right: 5px;
      position: absolute;
    }

    .dropdown {
      font-size: 14px;
      position: absolute;
      top: 35px;
      left: 0;
      right: 0;
      margin: 0;
      padding: 10px;
      background-color: white;
      border: 1px solid $color-blue;
      height: 135px;
      overflow-y: scroll;
      z-index: 5;

      &.searchable {
        height: 240px;
      }

      .searchInput {
        @include inputBox;
        margin-bottom: 10px;
      }

      ul {
        margin: 0;
        padding: 0;
        list-style: none;
      }

      li {
        padding: 8px;

        &:hover {
          background-color: transparentize($color: $color-blue, $amount: 0.9);
          cursor: pointer;
        }

        &.active {
          font-weight: bold;
          color: $color-blue;
        }

        .subtitle {
          color: $color-gray;
          font-size: 12px;
        }
      }
    }
  }

  .hintText {
    @include hintText;
  }
}
