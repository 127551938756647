@import "viewports";

.Col {
  padding-left: 10px;
  padding-right: 10px;

  &.Col-1-3 {
    $width: 33.3333%;
    max-width: $width;
    min-width: $width;

    @media (max-width: $max-laptop) {
      $width: 50%;
      max-width: $width;
      min-width: $width;
    }

    @media (max-width: $max-tablet) {
      $width: 100%;
      max-width: $width;
      min-width: $width;
    }
  }

  &.Col-2-3 {
    $width: 66.6666%;
    max-width: $width;
    min-width: $width;

    @media (max-width: $max-laptop) {
      $width: 100%;
      max-width: $width;
      min-width: $width;
    }
  }

  &.Col-1-2 {
    $width: 50%;
    max-width: $width;
    min-width: $width;

    @media (max-width: $max-tablet) {
      $width: 100%;
      max-width: $width;
      min-width: $width;
    }
  }

  &.Col-1-1 {
    $width: 100%;
    max-width: $width;
    min-width: $width;
  }
}
