@import "colors";

html,
body {
  font-family: "Poppins", sans-serif;
  color: rgba(0, 0, 0, 0.85);
  margin: 0;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.4;
}

a {
  color: $color-blue;

  &,
  &:link,
  &:hover,
  &:active,
  &:visited,
  &:focus {
    color: $color-blue;
  }
}

.standardForm {
  label {
    display: block;
    width: 100%;
    margin-bottom: 15px;
    text-align: left;

    & > span:first-child {
      margin-bottom: 3px;
      font-size: 12px;
      display: block;
    }

    & > span.hint {
      margin-top: 3px;
      font-size: 12px;
      display: block;
      color: $color-gray;
    }

    input[type="text"],
    input[type="email"],
    input[type="password"] {
      padding: 0 10px;
      width: 100%;
      height: 40px;
      border-radius: 0;
      appearance: none;
      border: 1px solid #ccc;

      &:focus {
        outline: none;
        background-color: transparentize($color: $color-blue, $amount: 0.9);
      }
    }
  }

  button {
    appearance: none;
    border: none;
    background-color: black;
    color: white;
    font-size: 14px;
    text-transform: uppercase;
    padding: 10px 20px;
    font-weight: bold;
    margin-bottom: 15px;
    cursor: pointer;

    &[type="submit"] {
      margin-top: 20px;
    }
  }
}
