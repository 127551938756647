@mixin inputContainer {
  display: block;
  margin-bottom: 25px;
  font-size: 0;
}

@mixin labelText {
  font-size: 14px;
  display: block;
  padding-bottom: 5px;
  font-weight: bold;
}

@mixin inputBox {
  appearance: none;
  height: 36px;
  border: 1px solid $color-gray;
  padding: 0 10px;
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 16px;
}

@mixin inputBoxFocused {
  outline: none;
  border-color: $color-blue;
  background-color: transparentize($color: $color-blue, $amount: 0.9);
}

@mixin hintText {
  font-size: 12px;
  display: block;
  color: $color-gray;
  padding-top: 5px;
}
