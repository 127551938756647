@mixin button {
  margin: 0 10px 10px;
  padding: 7px 13px;
  appearance: none;
  border: 2px solid black;
  background-color: transparent;
  font-weight: bold;
  font-size: 14px;
  text-transform: uppercase;
  cursor: pointer;

  &.primary {
    background-color: black;
    color: white;
  }

  &.sensitive {
    border-color: $color-red;
    background-color: $color-red;
    color: white;
  }
}

@mixin linkButton {
  margin: 0 10px 10px;
  appearance: none;
  border: none;
  background-color: transparent;
  font-size: 14px;
  cursor: pointer;
  text-decoration: underline;
  padding: 0;

  &.primary {
    color: $color-blue;
  }

  &.sensitive {
    color: $color-red;
  }
}
