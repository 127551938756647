@import "colors";

.Row {
  margin: 0 0 10px;
  display: block;
  padding: 15px;
  background: transparentize($color: $color-blue, $amount: 0.9);

  .title {
    font-weight: bold;
    display: flex;
    align-items: center;

    .titleText {
      margin-right: 10px;
    }

    a.titleText {
      &,
      &:link,
      &:hover,
      &:active,
      &:visited,
      &:focus {
        color: inherit;
        text-decoration: none;
      }

      &::after {
        content: ">";
        margin-left: 5px;
        display: inline-block;
        position: relative;
        left: 0;
        transition: left 0.2s;
      }

      &:hover::after {
        left: 5px;
      }
    }

    .actions {
      display: inline-block;
      opacity: 0;
      transition: opacity 0.2s;

      button,
      a {
        border: none;
        margin: 0;
        padding: 0;
        appearance: none;
        background-color: transparent;
        cursor: pointer;
        opacity: 0.5;
        transition: opacity 0.2s;
        margin-right: 5px;

        &,
        &:link,
        &:hover,
        &:active,
        &:visited,
        &:focus {
          color: inherit;
          text-decoration: none;
        }

        svg {
          font-size: 20px;
          margin-bottom: -4px;
        }

        &:hover {
          opacity: 1;
        }
      }
    }

    &:hover {
      & > .actions {
        opacity: 1;
      }
    }

    .orderActions {
      display: inline-block;
      margin-right: 10px;
      font-size: 0;

      button {
        appearance: none;
        border: none;
        background-color: transparent;
        padding: 0;
        margin: 0;
        font-size: 0;
        cursor: pointer;
        color: black;
        opacity: 0.5;
        transition: opacity 0.2s;

        &:hover {
          opacity: 1;
        }

        svg {
          font-size: 25px;
        }
      }
    }
  }

  .List {
    margin-top: 10px;
    border-left: 2px solid $color-blue;

    .Row {
      padding-top: 0;
      padding-bottom: 0;
      background-color: transparent;
      margin-bottom: 10px;
    }
  }
}
