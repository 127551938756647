@import "colors";

main.Logout {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: transparentize($color: $color-blue, $amount: 0.9);

  & > div {
    padding: 30px;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
}
