@import "colors";
@import "input";

.ToggleInput {
  @include inputContainer;

  .labelText {
    @include labelText;
  }

  input {
    appearance: none;
    height: 1px;
    width: 1px;
    border: none;
    opacity: 0;
    position: absolute;

    & + .toggle {
      height: 22px;
      margin-top: 7px;
      margin-bottom: 7px;
      width: 40px;
      border-radius: 11px;
      border: 1px solid $color-gray;
      display: inline-block;
      position: relative;
      cursor: pointer;

      &::after {
        content: "";
        display: inline-block;
        position: absolute;
        top: 3px;
        left: 4px;
        height: 14px;
        width: 14px;
        border-radius: 50%;
        background-color: $color-gray;
        transition: left 0.2s, background-color 0.2s;
      }
    }

    &:focus {
      outline: none;
      & + .toggle {
        border-color: $color-blue;
        background-color: transparentize($color: $color-blue, $amount: 0.9);
      }
    }

    &:checked {
      & + .toggle {
        &::after {
          background-color: $color-blue;
          left: 20px;
        }
      }
    }
  }

  .hintText {
    @include hintText;
  }
}
