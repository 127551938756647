@import "colors";
@import "input";

.RichtextInput {
  @include inputContainer;

  .labelText {
    @include labelText;
  }

  div[class^="MUIRichTextEditor-container"] {
    margin: 0;
    padding: 15px;
    border: 1px solid black;
    background-color: white;
  }

  div[class^="MUIRichTextEditor-toolbar"] {
    background-color: transparentize($color: $color-gray, $amount: 0.9);
    margin: -15px -15px 20px;
  }

  div[class^="MUIRichTextEditor-editor"] {
    min-height: 20px;
  }

  .hintText {
    @include hintText;
  }
}
