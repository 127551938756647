@import "colors";
@import "buttons";

.Popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  .popupWindow {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) translateZ(0);
    background-color: white;
    z-index: 500;
    padding: 30px;
    max-width: 630px;
    width: 100%;
    text-align: center;

    .title {
      font-weight: bold;
      font-size: 25px;
    }

    p {
      font-size: 14px;
    }

    .actions {
      padding-top: 10px;

      button {
        @include button;
      }
    }
  }

  .popupBackdrop {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 499;
  }
}
