@import "colors";

main.Login {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: transparentize($color: $color-blue, $amount: 0.9);

  form {
    padding: 30px;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 5px;
    max-width: 320px;
    width: 100%;

    .logoWrapper {
      text-align: center;
      padding-bottom: 30px;
    }

    .formWrapper {
      max-width: 500px;
      width: 100%;
      text-align: center;
    }
  }
}
