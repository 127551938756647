@import "colors";

.Announcements {
  .List {
    .Row {
      .more {
        .date {
          font-size: 14px;
          color: $color-gray;
        }
      }
    }
  }
}
