@import "colors";
@import "buttons";

main.mainArea {
  min-height: calc(100vh - 145px);
  padding: 30px 20px;
  max-width: 1500px;
  margin: 0 auto;

  .goBack {
    display: inline-block;
    margin-bottom: 20px;
    font-size: 12px;
    text-decoration: underline;
    cursor: pointer;
    appearance: none;
    background-color: transparent;
    border: none;
    color: $color-blue;
    padding: 0;
  }

  .breadcrumb {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      display: inline-block;
      color: $color-gray;
      font-size: 12px;
      margin-right: 10px;

      &::after {
        content: ">";
        display: inline-block;
        margin-left: 10px;
      }
    }
  }

  h1 {
    margin-top: 0;
    margin-bottom: 40px;
  }

  h2 {
    font-size: 16px;
    margin-top: 40px;
    margin-bottom: 20px;
  }

  .mainActions {
    padding-top: 30px;
    margin-top: 30px;
    border-top: 1px solid $color-gray;

    button {
      @include button;

      &:first-child {
        margin-left: 0;
      }
    }
  }

  .secondaryActions {
    button {
      @include linkButton;

      &:first-child {
        margin-left: 0;
      }
    }
  }
}
