@import "colors";
@import "viewports";

.Homepage {
  .navGroup {
    margin: 0 -10px;
    padding: 40px 0 20px;
    list-style: none;
    display: flex;
    flex-wrap: wrap;

    li {
      display: block;
      padding-left: 10px;
      padding-right: 10px;
      max-width: 50%;
      min-width: 50%;

      @media (max-width: $max-tablet) {
        max-width: 100%;
        min-width: 100%;
      }

      a {
        background-color: transparentize($color: $color-blue, $amount: 0.9);
        transition: background-color 0.2s;
        padding: 20px;
        display: block;
        margin-bottom: 20px;

        &,
        &:link,
        &:hover,
        &:active,
        &:visited,
        &:focus {
          color: inherit;
          text-decoration: none;
        }

        &:hover {
          background-color: transparentize($color: $color-blue, $amount: 0.7);
        }

        h2 {
          margin: 0;
        }

        p {
          margin-bottom: 5px;
          font-size: 14px;
        }
      }
    }
  }
}
