@import "colors";

main.ResetPasswordRequest {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: transparentize($color: $color-blue, $amount: 0.9);

  .goBack {
    display: inline-block;
    margin-bottom: 10px;
    font-size: 14px;
    text-decoration: underline;
    cursor: pointer;
    appearance: none;
    background-color: transparent;
    border: none;
    color: $color-blue;
  }

  form {
    padding: 30px;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 5px;
    max-width: 320px;
    width: 100%;

    .formWrapper {
      max-width: 500px;
      width: 100%;
      text-align: center;
    }
  }

  .resetDone {
    padding: 30px;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 5px;
    max-width: 320px;
    width: 100%;
  }
}
