@import "colors";

header {
  height: 100px;
  border-bottom: 1px solid $color-blue;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  button#menuOpen {
    position: absolute;
    top: 50%;
    left: 20px;
    transform: translateY(-50%);
    appearance: none;
    border: none;
    width: 30px;
    height: 30px;
    background-color: transparent;
    cursor: pointer;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0;

    &::before {
      content: url("./menu.svg");
    }
  }

  a {
    display: inline-block;
    font-size: 0;

    img {
      max-width: 50vw;
    }
  }
}

.backdrop {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  transition: opacity 0.2s;

  &.menu-closed {
    opacity: 0;
    pointer-events: none;
  }

  &.menu-open {
    opacity: 1;
  }
}

nav {
  position: fixed;
  z-index: 1010;
  top: 0;
  left: 0;
  bottom: 0;
  background-color: white;
  width: 300px;
  padding: 100px 20px 20px;
  max-width: 100vw;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.4);
  overflow-x: scroll;
  transition: box-shadow 0.2s, transform 0.2s;

  &.menu-closed {
    transform: translateX(-100%);
    box-shadow: none;
  }

  button#menuClose {
    position: absolute;
    top: 20px;
    right: 20px;
    appearance: none;
    border: none;
    width: 30px;
    height: 30px;
    background-color: transparent;
    cursor: pointer;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0;

    &::before {
      content: url("./close.svg");
    }
  }

  ul {
    margin: 0 0 20px;
    padding: 0;
    list-style: none;

    li {
      display: block;
      font-size: 14px;

      a {
        padding-top: 3px;
        padding-bottom: 3px;
        display: inline-block;
        transition: color 0.2s;

        &,
        &:link,
        &:hover,
        &:active,
        &:visited,
        &:focus {
          color: inherit;
          text-decoration: none;
        }

        &:hover {
          color: $color-blue;
        }

        &.is-active {
          color: $color-blue;
          font-weight: bold;
        }
      }
    }
  }

  #userDetails {
    padding: 15px 0 20px;
    position: absolute;
    bottom: 0;
    left: 20px;
    right: 20px;
    border-top: 1px solid $color-blue;
    font-size: 14px;

    #userBadge {
      display: flex;
      border-bottom: 1px solid $color-blue;
      padding-bottom: 5px;
      margin-bottom: 20px;

      .image {
        width: 20px;
        height: 20px;
      }

      .text {
        padding-left: 10px;
        padding-top: 2px;
        padding-bottom: 10px;

        .name {
          display: block;
          color: $color-blue;
          font-weight: bold;
        }
        .role {
          display: block;
          font-size: 12px;
        }
      }
    }

    .actions {
      a {
        display: block;
        margin-bottom: 5px;
        &,
        &:link,
        &:hover,
        &:active,
        &:visited,
        &:focus {
          color: inherit;
        }

        &:hover {
          color: $color-blue;
        }
      }
    }
  }
}
