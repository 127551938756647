@import "colors";
@import "input";

.DateInput {
  @include inputContainer;

  .labelText {
    @include labelText;
  }

  .react-datepicker-wrapper {
    width: 100%;
    input {
      @include inputBox;

      &:focus {
        @include inputBoxFocused;
      }
    }
  }

  .hintText {
    @include hintText;
  }
}
