@import "colors";

.ErrorToast {
  position: fixed;
  bottom: 20px;
  width: 100%;
  max-width: 320px;
  background-color: $color-red;
  padding: 10px;
  font-size: 12px;
  color: white;
  border-radius: 5px;

  p {
    margin: 0;

    &.title {
      font-weight: bold;
    }
  }

  button {
    appearance: none;
    border: none;
    background-color: transparent;
    padding: 7px;
    margin: 0;
    top: 0;
    right: 0;
    position: absolute;
    color: white;
    cursor: pointer;

    svg {
      font-size: 16px;
    }
  }
}
