@import "colors";
@import "input";

.TextInput {
  @include inputContainer;

  .labelText {
    @include labelText;
  }

  input {
    @include inputBox;

    &:focus {
      @include inputBoxFocused;
    }
  }

  .hintText {
    @include hintText;
  }
}
