@import "colors";

.Message {
  padding: 10px 20px;
  font-size: 14px;
  border: 1px solid $color-blue;
  background-color: transparentize($color: $color-blue, $amount: 0.9);
  margin-bottom: 20px;

  .title {
    font-weight: bold;
    color: $color-blue;
  }

  &.warning {
    background-color: transparentize($color: $color-red, $amount: 0.9);
    border-color: $color-red;
    .title {
      color: $color-red;
    }
  }
}
