@import "colors";
@import "input";

.MultipleInput {
  @include inputContainer;

  .labelText {
    @include labelText;
  }

  .hintText {
    @include hintText;
    padding-top: 0;
    padding-bottom: 5px;
  }

  .items {
    font-size: 12px;
    .singleItem {
      padding: 25px 25px 10px;
      background-color: transparentize($color: $color-gray, $amount: 0.9);
      border-left: 2px solid $color-gray;
      margin-top: 15px;

      .actions {
        margin-bottom: 10px;
        button {
          font-size: 10px;
          color: $color-gray;
          cursor: pointer;
          appearance: none;
          border: none;
          padding: 0;
          margin: 0 10px 0 0;
          background-color: transparent;
        }
      }
    }
  }

  &.simpleItem {
    .singleItem:not(.addRow) {
      display: flex;
      padding-bottom: 0;
      padding-left: 12px;
      padding-right: 17px;
      padding-top: 10px;

      .actions {
        button {
          position: relative;
          top: 5px;
        }
      }
      .content {
        flex: 1;
        margin-bottom: -14px;
      }
    }

    .singleItem.addRow {
      display: flex;
      padding-bottom: 0;
      padding-left: 12px;
      padding-right: 17px;
      padding-top: 10px;

      .actions {
        margin-bottom: 7px;
      }
    }
  }
}
