@import "colors";
@import "input";

.FileInput {
  @include inputContainer;

  .labelText {
    @include labelText;
  }

  .fileArea {
    font-size: 12px;

    .FileRenderer {
      font-size: 14px;

      .actions {
        button {
          font-size: 12px;
          appearance: none;
          background-color: transparent;
          border: none;
          padding: 0;
          color: $color-blue;
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
  }

  .hintText {
    @include hintText;
  }
}
